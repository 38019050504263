import {
  //Select,
  TextInput,
  Message,
  Button,
  RadioGroup,
  Typography,
  FloatButton,
} from '../../utils/antd-components/index.tsx';
import { Form } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useState, useEffect } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import { ApiUtils } from '../../utils/api.jsx';
import { useTranslation } from 'react-i18next';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import routesnkt from '../../routes/routes.json';
import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';

const CreateAsset = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const roles = authUser.rolesType;
  const [successMessage, setsuccessMessage] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    content: '',
    status: 1,
  });
  const [publishError, setPublishError] = useState(null);
  const [, setUsersList] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      const fetchUsers = async () => {
        const URL = PATHS.USER.GET + '?status=1&limit=1000';
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          // const filteredUsers = data.users.filter(
          //   (user) => user.roleId?.type === 'Project Manager',
          // );
          // setUsersList(filteredUsers);
          setUsersList(data.users);
        }
      };

      fetchUsers();
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const handleSubmit = async (e) => {
    setLoading(true);
    // e.preventDefault();
    console.log('Received values of form: ', e);
    try {
      const payload = {
        ...e,
        // imageUrl: formData.imageUrl,
        status: formData.status,
      };
      if (roles.includes('admin')) {
        const URL = PATHS.ASSET.ADD;
        const data = await ApiUtils(URL, payload, METHOD_TYPES.POST);
        console.log('data++', data);
        setLoading(true);

        if (data.status !== 200) {
          setPublishError(data.message);
          setLoading(false);
          return;
        } else {
          setsuccessMessage(data.response.messsage);
          setPublishError(null);
          navigate(routesnkt.asset);
        }
      }
      setLoading(false);
    } catch (error) {
      console.log('error', error);

      setPublishError(t('something-went-wrong'));
    }
  };
  const handleRadioChange = (e) => {
    const statusValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: statusValue,
    }));
  };

  return (
    <div className="p-3 max-w-3xl mx-auto min-h-screen">
      <FloatButton.BackTop />
      <TitleAndBackComponent title={t('CreateAsset')} goBackText={t('back')} />
      <Form onFinish={handleSubmit}>
        <div className="flex flex-row gap-4 justify-between">
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('assetType')} />
            <Form.Item
              name="asset_type"
              rules={[{ required: true, message: t('Asset type is required') }]}
            >
              <TextInput placeholder={t('Assettype')} />
              {/* <Select
                className={'h-10'}
                required={false}
                onChange={(value) => {
                  setFormData({ lead_name: value });
                }}
                selectedValue={formData.lead_name}
                options={typeList}
                placeholder={t('selectAsset')}
                label={'first_name'}
                id={'first_name'}
              /> */}
            </Form.Item>
          </div>

          <div className="w-full flex flex-col gap-2">
            <Typography value={t('assetTitle')} />
            <Form.Item
              name="assetTitle"
              rules={[
                { required: true, message: t('Asset title is required') },
              ]}
            >
              <TextInput
                // value={formData.dept_name}
                placeholder={t('assetTitle')}
              />
            </Form.Item>
            {/* <Form.Item
              name="assetTitle"
              rules={[
                { required: true, message: t('Asset title is required') },
              ]}
            >
              <Select
                className={'h-10'}
                required={false}
                onChange={(value) => {
                  setFormData({ assetTitle: value });
                }}
                selectedValue={formData.assetTitle}
                options={AssetsList}
                placeholder={t('selectaUser')}
                label={'first_name'}
                id={'first_name'}
              />
            </Form.Item> */}
          </div>
        </div>
        <div className="flex flex-col gap-4 sm:flex-col justify-between">
          <Typography value={t('desc')} />
          <Form.Item name="description" rules={[{ required: false }]}>
            <ReactQuill
              theme="snow"
              placeholder={t('writeSomething')}
              className="h-40 mb-12"
            />
          </Form.Item>
        </div>
        <div className="flex flex-row gap-2 sm:flex-col justify-between ">
          <Typography value={t('Status')} />
          <Form.Item name="status" rules={[{ required: true }]}>
            <RadioGroup
              onChange={handleRadioChange}
              selectedValue={formData.status}
              t={t}
            />
            {/* <Radio.Group>
              <Radio value="1">{t('active')}</Radio>
              <Radio value="2">{t('inActive')}</Radio>
            </Radio.Group> */}
          </Form.Item>
        </div>
        <div className="flex justify-end">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            {t('CreateAsset')}
          </Button>
        </div>
      </Form>
      {publishError && <Message successMessage={publishError} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
};

export default CreateAsset;
