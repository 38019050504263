import { Menu, Layout, Dropdown } from 'antd';
import {
  HiUser,
  HiArrowSmRight,
  HiOutlineUserGroup,
  HiChartPie,
  HiCalendar,
} from 'react-icons/hi';
import { MdAdminPanelSettings, MdAssignmentReturned } from 'react-icons/md';
import { VscProject } from 'react-icons/vsc';
import { CiSettings } from 'react-icons/ci';
// import { BsClockFill } from 'react-icons/bs';
import { BiSolidCategory } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signoutSuccess } from '../redux/user/userSlice';
import { useDispatch } from 'react-redux';
import { TbChristmasTree, TbReportSearch } from 'react-icons/tb';
import { clearAuthToken } from '../utils/api';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { LuCrown } from 'react-icons/lu';
import { IoMenu } from 'react-icons/io5';
import routesConfig from '../routes/routes.json';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { BsCalendarWeekFill } from 'react-icons/bs';
import { SlUserUnfollow } from 'react-icons/sl';
import { MdOutlineHomeWork } from 'react-icons/md';

export default function DashSidebar() {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const roles = authUser?.rolesType;
  const userId = authUser?._id;
  const { Sider } = Layout;

  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const [tab, setTab] = useState('');
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tabFromUrl = urlParams.get('tab');
    if (tabFromUrl) {
      setTab(tabFromUrl);
    } else {
      setTab(location.pathname);
    }
  }, [location.search, location.pathname]);

  const handleSignout = async () => {
    try {
      signOut();
      clearAuthToken();
      dispatch(signoutSuccess());
      navigate('/');
    } catch (error) {
      console.log(error.message);
    }
  };

  function getItem(key, icon, title, label, children) {
    return {
      key,
      icon,
      title,
      label,
      children,
    };
  }
  let menuItems = [];
  // menuItems.push(
  //   getItem(
  //     'home',
  //     null,
  //     '',
  //     <Link to="/">
  //       {collapsed ? (
  //         <img src={NktSingle} alt="user" className="h-full w-auto" />
  //       ) : (
  //         <img src={Netkathir} alt="user" className="w-full md:w-1/2 " />
  //       )}
  //     </Link>,
  //   ),
  // );

  const adminMenuItems = [
    getItem(
      routesConfig.dashboard,
      <HiChartPie />,
      t('dashboard'),
      <Link to={routesConfig.dashboard}>{t('dashboard')}</Link>,
    ),
    getItem('att-key', <HiCalendar />, t('Attendance'), t('Attendance'), [
      getItem(
        routesConfig.attendance,
        <BsCalendarWeekFill />,
        t('attendList'),
        <Link to={routesConfig.attendance}>{t('attendList')}</Link>,
      ),
      getItem(
        routesConfig.absentList,
        <SlUserUnfollow />,
        t('absentList'),
        <Link to={routesConfig.absentList}>{t('absentList')}</Link>,
      ),
      getItem(
        routesConfig.wfoList,
        <MdOutlineHomeWork />,
        'WOH Absent List',
        <Link to={routesConfig.wfoList}>{'WFO List'}</Link>,
      ),
      getItem(
        routesConfig.holidayList,
        <TbChristmasTree />,
        t('holidayList'),
        <Link to={routesConfig.holidayList}>{t('holidayList')}</Link>,
      ),
      getItem(
        routesConfig.attendanceReport,
        <AiOutlineFileSearch />,
        t('Report'),
        <Link to={routesConfig.attendanceReport}>{t('Report')}</Link>,
      ),
      getItem(
        routesConfig.attendanceYaerlyReport,
        <SlUserUnfollow />,
        t('ReportYearly'),
        <Link to={routesConfig.attendanceYaerlyReport}>
          {t('ReportYearly')}
        </Link>,
      ),
    ]),
    getItem(
      routesConfig.roles,
      <MdAdminPanelSettings />,
      t('roles'),
      <Link to={routesConfig.roles}>{t('roles')}</Link>,
    ),
    getItem(
      routesConfig.department,
      <MdAdminPanelSettings />,
      t('department'),
      <Link to={routesConfig.department}>{t('department')}</Link>,
    ),
    //asset
    getItem(
      routesConfig.asset,
      <MdAdminPanelSettings />,
      t('assets'),
      <Link to={routesConfig.asset}>{t('asset')}</Link>,
    ),

    getItem(
      routesConfig.users,
      <HiOutlineUserGroup />,
      t('employees'),
      <Link to={routesConfig.users}>{t('employees')}</Link>,
    ),
    getItem('project-Sidebar', <VscProject />, t('projects'), t('projects'), [
      getItem(
        routesConfig.projects,
        <VscProject />,
        t('projects'),
        <Link to={routesConfig.projects}>{t('projects')}</Link>,
      ),
      getItem(
        routesConfig.subProjects,
        <VscProject />,
        t('subProjects'),
        <Link to={routesConfig.subProjects}>{t('subProjects')}</Link>,
      ),
    ]),
    getItem(
      routesConfig.projectsAllocate,
      <MdAssignmentReturned />,
      t('assign-proj'),
      <Link to={routesConfig.projectsAllocate}>{t('assign-proj')}</Link>,
    ),
    // getItem(
    //   routesConfig.clockIns,
    //   <BsClockFill />,
    //   t('clock-ins'),
    //   <Link to={routesConfig.clockIns}>{t('clock-ins')}</Link>,
    // ),
    getItem(
      routesConfig.config,
      <MdAssignmentReturned />,
      t('config'),
      <Link to={routesConfig.config}>{t('config')}</Link>,
    ),
  ];

  if (roles?.includes('admin')) {
    menuItems = menuItems.concat(adminMenuItems);
  }

  menuItems.push(
    getItem(
      routesConfig.profile,
      <HiUser />,
      t('profile'),
      <Link to={routesConfig.profile}>{t('profile')}</Link>,
    ),
    getItem(
      routesConfig.modules,
      <BiSolidCategory />,
      t('module/activity'),
      <Link to={routesConfig.modules}>{t('module/activity')}</Link>,
    ),
    getItem(
      routesConfig.workLogReport,
      <TbReportSearch />,
      t('work-log'),
      <Link to={routesConfig.workLogReport}>{t('work-log')}</Link>,
    ),
    getItem(
      'administration',
      <LuCrown />,
      t('administration'),
      t('administration'),
      [
        getItem(
          routesConfig.settings,
          <CiSettings />,
          t('settings'),
          <Link to={routesConfig.settings}>{t('settings')}</Link>,
        ),
        {
          key: 'signOut',
          icon: <HiArrowSmRight />,
          label: t('signOut'),
          onClick: handleSignout,
        },
      ],
    ),
  );

  const menuItemStyle = (item) => ({
    color: item.key === tab ? 'green' : 'inherit',
  });

  return (
    <>
      {userId && window.innerWidth < 768 && (
        <Dropdown style={{ marginLeft: 10 }} menu={{ items: menuItems }}>
          <button
            style={{
              width: 'fit-content',
              position: 'fixed',
              top: '25px',
              zIndex: 999,
              left: '20px',
            }}
          >
            <IoMenu style={{ fontSize: '1.5rem' }} />
          </button>
        </Dropdown>
      )}
      {userId && window.innerWidth > 768 && (
        <Sider
          collapsible
          className="site-layout-background"
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          // trigger={null}
          style={{
            overflow: 'auto',
            position: 'sticky',
            top: 70,
            left: 0,
            height: '100vh',
            zIndex: 99,
          }}
        >
          <Menu
            mode="inline"
            selectedKeys={[tab]}
            items={menuItems.map((item) => ({
              ...item,
              label: <span style={menuItemStyle(item)}>{item.label}</span>,
            }))}
          />
        </Sider>
      )}
    </>
  );
}
