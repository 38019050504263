import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { ConfigProvider, theme as antdTheme } from 'antd';
import CSSUtility from '../utils/CSSUtility.js';

interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const { theme } = useSelector(
    (state: { theme: { theme: string } }) => state.theme,
  );
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary:
            theme === 'dark'
              ? CSSUtility.darkcolor.primary
              : CSSUtility.color.primary,
          colorInfo:
            theme === 'dark'
              ? CSSUtility.darkcolor.primary
              : CSSUtility.color.primary,
        },
        components: {
          Menu: {
            itemSelectedColor: '#419873',
          },
          Pagination: {
            colorPrimary: 'rgb(19, 116, 252)',
          },
          Spin: {
            colorPrimary: theme === 'dark' ? 'rgb(255, 253, 8)' : '#419873',
          },
        },
        algorithm:
          theme === 'dark'
            ? antdTheme.darkAlgorithm
            : antdTheme.defaultAlgorithm,
      }}
    >
      <div className={`${theme}`}>
        <div className=" ant-layout-header ant-layout bg-slate-900 text-gray-700 dark:text-gray-200 dark:bg-[rgb(20,20,20)] min-h-screen">
          {children}
        </div>
      </div>
    </ConfigProvider>
  );
};

export default ThemeProvider;
